import * as React from "react"
import { Link } from "gatsby"

import { DefaultLayout } from "../layouts";
import { Container, SEO } from "../components";

import styles from "./404/404.module.scss";

const NotFoundPage = () => {
	return (
		<DefaultLayout>
			<SEO title="Not Found" />
			<div className={styles.core}>
					<Container className={styles.container}>
					<div className={styles.header}>
						<title>Not found</title>
						<h1>Página no encontrada.</h1>
					</div>
					<div className={styles.body}>
						<p className={styles.paragraph}>
							Lo siento{" "}
							<span role="img" aria-label="Pensive emoji">
							😔
							</span>{" "}
							no hemos podido encontrar la página que buscas.
							<br />
							<br />
							<Link to="/" className={styles.link} >Inicio</Link>
						</p>
					</div>
				</Container>
			</div>
		</DefaultLayout>
	)
}

export default NotFoundPage
		